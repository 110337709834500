import React, { useState } from 'react';
import './App.css';


//import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../Login/Login.js';
import Dashboard from '../Dashboard/Dashboard.js';
import Preferences from '../Preferences/Preferences.js';
import useToken from './useToken';


function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}

function App() {
  const { token, setToken } = useToken();

  //setToken( localStorage.getItem('token') );
  const token1 = localStorage.getItem('token');
  console.log("token:::::::",token1);
  if(!token1) {
    return <Login setToken={setToken} />
  }
  return (
    <div className="wrapper">
      
      <BrowserRouter>
        <Switch>
        <Route path="/">
            <Dashboard />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/preferences">
            <Preferences />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
export default App;