import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";

//import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//import Icon from '@mui/material/Icon';
import { auth, firebase } from "../../firebase";
import { useHistory  } from "react-router-dom";

import './Login.css';

async function loginUser(credentials) {
  return fetch('http://localhost:5000/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function Login({ setToken }) {
  
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const backgroundImageUrl ='https://kitchen-os-assets.s3.amazonaws.com/images/login.jpg';

  const history = useHistory();
  var  token;
  async function googleLogin() {

    const provider = new firebase.auth.GoogleAuthProvider();

    /*provider.addScope('https://mail.google.com/');
    provider.addScope('https://www.googleapis.com/auth/drive');
    provider.addScope('https://www.googleapis.com/auth/drive.file');
    provider.addScope('https://www.googleapis.com/auth/gmail.labels');
    provider.addScope('https://www.googleapis.com/auth/drive.metadata');
    provider.addScope('https://www.googleapis.com/auth/gmail.modify');*/
    
    console.log(auth?.currentUser?.email);
    console.log(auth?.currentUser?.getIdToken(true));
    console.log(auth?.currentUser);
     //1 - init Googl Auth Provider
     var  token;
     //2 - create the popup signIn
     await auth.signInWithPopup(provider).then(
       async (result) => {

        console.log("result",result);
         //3 - pick the result and store the token
         token = await auth?.currentUser?.getIdToken(true);
         console.log("token:::",token);
         console.log("auth:::",auth);
         
         //console.log(auth?.currentUser?.email);
         if(auth?.currentUser?.email.split("@")[1]=="foodology.com.co" || auth?.currentUser?.email=="foodologycol@gmail.com")
         {
          //4 - check if have token in the current user
          if (token!="" && token != null && token != undefined) {            
            //5 - put the token at localStorage (We'll use this to make requests)
            localStorage.setItem("@token", token);
            localStorage.setItem('token', '{"id":"s","token":"'+token+'"}');
            sessionStorage.setItem('token', '{"id":"s","token":"'+token+'"}');

            //6 - navigate user to the book list
            setToken(token);
            console.log(token);
            
            window.location.href="/dashboard";  

          }
          else{
            
            alert("usuario No permitido");
          }
        }
        
       },
       function (error) {
         console.log(error);
       }
     );

    
   }


  const handleSubmit = async e => {
    e.preventDefault();
    console.log(e,username,password);
    
      token = await loginUser({
        username,
        password
      });
    
    setToken(token);
  }

  return(
    <div>

    
      <Grid container component="main" sx={{height:'100vh'}}>
      <Grid
        item
        xs={false}
        sm={8}
        md={9}
        lg={9}
        sx={{
          backgroundImage: 'url(https://kitchen-os-assets.s3.amazonaws.com/images/login.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        item
        xs={12}
        sm={4}
        md={3}
        lg={3}
        component={Paper}
        elevation={6}
        square
      >        
         
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>            
              <>
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  id="identification"
                  label="Cédula"
                  name="identification"
                  InputLabelProps={{ required: false }}
                  sx={{ backgroundColor: '#FFFFFF' }}                  
                />
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  InputLabelProps={{ required: false }}
                  sx={{ backgroundColor: '#FFFFFF' }}                  
                />
                <Button type="submit"  fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  INGRESAR
                </Button>

              </>
              <Stack direction="row"  justifyContent="center">
              <img src='/images/but_google.png' onClick={googleLogin} style={{width:'200px'}}/>
            </Stack>
            </Box>
            
                 
          </Grid>
      </Grid>
      
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
