//import { useMemo, useRef } from "react";
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';


import { FormControl, InputLabel, Button, Grid, Select, MenuItem, TextField, Stack } from '@mui/material';

import PdfIcon from '@mui/icons-material/PictureAsPdf';
import ZipIcon from '@mui/icons-material/FolderZip';
import XmlIcon from '@mui/icons-material/DocumentScanner';


import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import { useForm } from 'react-hook-form';

import envConfig from '../../env';



import "./email.css";
import { isOptionGroup } from '@mui/base';

var email = "foodologycol@gmail.com"

const columns = [
  { field: 'id', headerName: 'ID', width: 120 },
  {
    field: 'date',
    headerName: 'Fecha',
    width: 150,
    editable: false,
  },
  {
    field: 'subject',
    headerName: 'Asunto',
    width: 350,
    editable: false,
  },

  {
    field: 'from',
    headerName: 'Email',
    width: 200,
    editable: false,
  },
  {
    field: "order",
    headerName: "Orden",
    width: 90,
    disableClickEventBubbling: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <TextField
        onChange={(e) =>
          params.api.updateRows([{ ...params.row, order: e.target.value }])
        }
      />
    )
  },

  {
    field: "zip",
    headerName: "Zip",
    sortable: false,
    width: 90,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <Button variant="contained" color="primary" value="zip" startIcon={<ZipIcon />} />
      );
    }
  },
  {
    field: "pdf",
    headerName: "Pdf",
    sortable: false,
    width: 90,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (

        <Button variant="contained" color="primary" value="pdf" startIcon={<PdfIcon />} />

      );
    }
  },
  {
    field: "xml",
    headerName: "Xml",
    sortable: false,
    width: 90,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <Button variant="contained" color="primary" value="xml" startIcon={<XmlIcon />} />

      );
    }
  }
];

async function viewDoc(id, type) {
  fetch(envConfig.apiUrl + 'download?email=' + email + '&messageId=' + id + '&type=' + type, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `File${id}.${type}`,
      );
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener');
      //console.log(link);
      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      if (type === "pdf")
        window.open(url);
      else link.click();


      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });

}



/*async function sendDrive(id, type) {
  fetch(envConfig.apiUrl + 'savedrive?messageId=' + id + '&type=pdf', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(response => response.json())
    .then(data => console.log(data));

}*/


async function getMessages(filters) {
  console.log(filters);
  console.log(process.env.url)
  return fetch(envConfig.apiUrl + 'messages?email=' + email + '&params=' + filters, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(data => data.json())
}

//const data = [];
var data1 = [];


function useApiRef() {
  const apiRef = React.useRef(null);
  const _columns = React.useMemo(
    () =>
      columns.concat({
        field: "__HIDDEN__",
        width: 0,
        renderCell: (params) => {
          apiRef.current = params.api;
          return null;
        }
      }),
    [columns]
  );

  return { apiRef, columns: _columns };
}

async function sendInvoice(order) {

  return fetch(envConfig.apiUrl + 'sendinvoice?email=' + email, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: '{"order":"' + order + '"}'
  })
    .then(data => data.json())

}

const data = [];
export default function DataGridEmails({ handleSearch }) {
  const [rows, setRows] = React.useState(data);
  const [loaded, setLoaded] = React.useState(false);
  const { apiRef, columns } = useApiRef();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [alert, setAlert] = React.useState("");
  const [open, setOpen] = React.useState(false);  


  React.useEffect(() => {
    getDataEmails(handleSearch);
  }, [handleSearch]);

  React.useEffect(() => {
    getDataEmails();
  }, []

  )


  const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        //console.log(me)
        ///this.mensaje=()=>{return JSON.stringify(data, null, 4)}
        console.log(data);
        //botonClick(JSON.stringify(data, null, 4));
    }




  const handleSendEmails = async (e) => {
    setLoaded(true);
    //console.log(apiRef.current.getRowModels());
    apiRef.current.getRowModels().forEach(element => {
      if (element.hasOwnProperty("order")) {
        let data = sendInvoice(element.order);
        //console.log(data)
      }
    })
    setLoaded(false);

  };

  async function getDataEmails(filters) {
    if (filters)
      console.log("filters", filters)
    setLoaded(true);
    data1 = [];
    let data = await getMessages(filters);
    console.log(data.lenght, data)
    console.log(typeof data)
    if (data.hasOwnProperty("url_auth")) {
      window.location.href = data.url_auth;
    }

    data.forEach(element => {
      let subject = "", date = "", from = "";

      element.data.payload.headers.forEach(item => {
        if (item.name === "Date") {
          date = item;
        }
        if (item.name === "From") {
          from = item;
        }
        if (item.name === "Subject") {
          subject = item;
        }
      })

      data1.push({ id: element.data.id, date: date.value, subject: subject.value, from: from.value });
    });
    setRows(data1);
    setLoaded(false);
    setSelectionModel([]);

  }
  const handleLoadEmails = async (e) => {
    await getDataEmails()
  };

  const handleSendDrive = async (e) => {


    if (selectionModel.length < 1) {
      setOpen(true);
      setAlert("Por favor seleccione almenos un item");
      return;
    }

    setLoaded(true);

    let orders = [];
    apiRef.current.getRowModels().forEach(element => {
      if (selectionModel.findIndex(it => it === element.id) >= 0) {
        if (element.hasOwnProperty("order")) {
          orders.push({ "id": element.id, "order": element.order });
        } else {
          orders.push({ "id": element.id, "order": "" });
        }
      }
    })





    fetch(envConfig.apiUrl + 'savedrive?email=' + email, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "mails": orders })
    })
      .then(response => response.json())
      .then(data => {
        let message = "";
        data.forEach(a => {
          message += a.messageId + ": BC:" + (a.statusBC ? "Enviado" : "----") + "<br/>";
        })
        setOpen(true);
        setAlert("Emails procesados...<br/>" + message);
        setTimeout(getDataEmails(), 5000);
        setLoaded(false);
        console.log("data", data)
        //alert("Se procesaron "+data.length+" emails")        

      });
  }

  function currentlySelected(params) {
    let type = (params.colDef.field === "zip") ? "zip" : ((params.colDef.field === "pdf") ? "pdf" : ((params.colDef.field === "xml") ? "xml" : ""))
    if (type !== "")
      viewDoc(params.id, type);
  }

  const handleChangeEmail = async (event) => {        
     email = event.target.value;
     await getDataEmails();
  };


  return (
    <div style={{ height: '100%', width: '100%' }}>
      <form>
        <Grid item md={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor='Email'>Cuenta Correo</InputLabel>
            <Select
              id="email"
              value={email}
              label="Email"
              inputProps={register('email')}
              onChange={handleChangeEmail}
            >
              <MenuItem value="foodologycol@gmail.com">foodologycol@gmail.com</MenuItem>
              <MenuItem value="facturascolombia@foodology.com.co">facturascolombia@foodology.com.co</MenuItem>
              <MenuItem value="facturacionperu@foodology.com.co">facturacionperu@foodology.com.co</MenuItem>
              <MenuItem value="facturacionmexico@foodology.com.co">facturacionmexico@foodology.com.co</MenuItem>
            </Select>
          </FormControl>
        </Grid>        
      </form>

      <DataGrid
        style={{ height: '70vh' }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}

        loading={loaded}
        onCellClick={currentlySelected}
      />

      <Stack spacing={2} direction="row" justifyContent="center">

        <Button onClick={handleLoadEmails} fullWidth variant="contained" >
          Cargar Emails
        </Button>

        <Button onClick={handleSendEmails} fullWidth variant="contained" >
          Enviar a Bc
        </Button>

        <Button onClick={handleSendDrive} fullWidth variant="contained" >
          Migrar a Drive
        </Button>
      </Stack>

      <Box sx={{ width: "100%" }}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  //setAlert("RE OPEN");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alert}
          </Alert>
        </Collapse>

      </Box>



    </div >
  );
}