
let apiUrlText= "https://integrator-test-326822.ue.r.appspot.com/";
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
{
    apiUrlText= "http://localhost:5000/"
}
const envConfig = {
    apiUrl: apiUrlText
};

export default envConfig;