

/**
 * src/firebase.js
 */
 import firebase from 'firebase/compat/app';
 import 'firebase/compat/auth';
 
 const firebaseConfig = {
    apiKey: "AIzaSyAIXZOnBGYpZsaexfRXrFMyhJlzf947TrU",
    authDomain: "invoice-foodology.firebaseapp.com",
    projectId: "invoice-foodology",
    storageBucket: "invoice-foodology.appspot.com",
    messagingSenderId: "66041537856",
    appId: "1:66041537856:web:c7edc5a1c5dd22b28346a5"
  };
 
 firebase.initializeApp(firebaseConfig);
 
 const auth = firebase.auth();
 
 export { auth, firebase };
 
 