import * as React from 'react';

import './filters.css';

import { FormControl, InputLabel, Button, Grid, Select, MenuItem, TextField } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { useForm } from 'react-hook-form';

import envConfig from '../../env';


var data = [];


var email = "foodologycol@gmail.com";

//getDataLabels();


async function getLabels() {
    return fetch(envConfig.apiUrl + 'labels?email=' + email, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(data => data.json())
}


export default function Filters({ botonClick }) {

    


    const [label, setLabel] = React.useState("");    
    const [optionsLabel, setOptionsLabel] = React.useState([]);
    

    const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

    const handleChangeDate = (newValue) => {
        setValue(newValue);
    };


    React.useEffect(() => {
        getDataLabels();
    }, []

    )

    const handleChangeLabel = (event) => {
        console.log("LABELS", event.target.value)
        setLabel(event.target.value);
    };

    const handleSearch = async (event) => {
        console.log(event.target.value)
    };




    async function getDataLabels() {

        let data1 = [];
        let data = await getLabels();
        console.log("data------------------------", data);
        data.data.labels.forEach(element => {
            data1.push(<MenuItem value={element.name}>{element.name}</MenuItem>);
        })
        setOptionsLabel(data1);        
    }

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        //console.log(me)
        ///this.mensaje=()=>{return JSON.stringify(data, null, 4)}
        console.log(data);
        botonClick(JSON.stringify(data, null, 4));
    }




    return (
        <Grid Contaniner>

            <form /*onSubmit={handleSubmit(onSubmit)}*/>

                <Grid item md={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='Labels'>Labels</InputLabel>
                        <Select
                            id="labels"
                            value={label}
                            label="Labels"
                            inputProps={register('label')}
                            onChange={handleChangeLabel}
                        >
                            {optionsLabel}
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item md={12}>
                    <FormControl>


                    </FormControl>
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Fecha Inicial"
                        id="fechaini"
                        inputFormat="yyyy/MM/dd"
                        value={value}
                        inputProps={register('fechaini')}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <DesktopDatePicker
                        label="Fecha Final"
                        id="fechafin"
                        inputFormat="yyyy/MM/dd"
                        value={value}
                        inputProps={register('fechafin')}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params} />}
                    />

                </LocalizationProvider>
                <Grid item md={12}>
                    <Button onClick={handleSubmit(onSubmit)} /*type="submit"*/ variant='contained' color='primary'>Buscar</Button>
                </Grid>
            </form>
        </Grid>
    )
}
